













import FileApi from 'piramis-base-components/src/components/File/logic/file-api'
import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue'

import Vue from 'vue'
import { Component, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ZoomableImage
  }
})
export default class FormSuccessMessageImageList extends Vue {
  @VModel() attachments!:Array<string>

  @Prop() boardKey!: string

  fileApi = new FileApi('api.presscode.info/cabinet')

  getImagePreview(descriptor) {
    return `${ this.fileApi.getApiUrl() }/getfilepreview?file_descriptor=${ descriptor }&target=${ this.boardKey }`
  }
}
