var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-success-message-setting"},[_c('upload-media-modal',{attrs:{"media-props":{
      baseApiUrl: 'api.presscode.info/cabinet',
      'targetToUpload': function () {
        return {
          'target': 'target',
          'target_id': _vm.$store.state.boardsState.activeBoard.board
        }
      }
    },"files-data":_vm.attachments,"blacklist-media-button-types":_vm.omittedTypes},on:{"fileLoaded":_vm.filesDataChange,"removeFile":_vm.removeFile},model:{value:(_vm.mediaModelOpen),callback:function ($$v) {_vm.mediaModelOpen=$$v},expression:"mediaModelOpen"}}),_c('config-field',{staticClass:"w-full mx-2 py-2 upload-wrapper",attrs:{"title":_vm.$t('form_success_attachments_title')}},[_c('div',{staticClass:"flex my-2 overflow-x-auto"},[_c('files-list-row-scroll',{attrs:{"files-data":_vm.attachments},on:{"removeFile":_vm.removeFile}}),_c('upload-media-trigger-area',{attrs:{"type":_vm.attachments.length ? 'small' : 'large'},on:{"onAreaClick":function($event){_vm.mediaModelOpen = true}}})],1)]),(_vm.formSuccess.success_message)?_c('editor-input',{staticClass:"form-message-success-editor",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.formSuccess.success_message,
        'key': 'text',
        'validation': (!_vm.formSuccess.success_message.text.length || !_vm.attachments.length) ? 'required' : '',
        'prefix': 'form_success_',
        'disabledPanelElements': ['tgspoiler', 'strike'],
      }
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }